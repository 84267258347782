<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Diagnosa</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-6" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpDiagnosa" class="form-control" 
                                placeholder="Ketik Diagnosa" />
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchDiagnosa" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetDiagnosa">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwDiagnosa">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataDiagnosa.diagnosa" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>
            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Poli</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-6" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpPoli" class="form-control" 
                                placeholder="Ketik Poli" />
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchPoli" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetPoli">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwPoli">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataPoli.poli" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Faskes</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-8" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpFaskes" class="form-control" 
                                placeholder="Ketik Faskes" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group mb-0">
                                    <v-select placeholder="Pilih Jenis Faskes" v-model="jnsFaskes" :options="Config.mr.jenisFaskes" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchFaskes" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetFaskes">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwFaskes">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataFaskes.faskes" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>
            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK DPJP</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-8" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpDPJP" class="form-control" 
                                placeholder="Ketik Kode DPJP" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group mb-0">
                                    <v-select placeholder="Pilih Jenis Pelayanan" v-model="jnsDPJP" :options="Config.mr.jenisPelayanan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchDPJP" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetDPJP">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwDPJP">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataDPJP.list" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Provinsi</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwProvinsi">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataProvinsi.list" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>
            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Kabupaten</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-6" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpKabupaten" class="form-control" 
                                placeholder="Ketik Kode Provinsi" />
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchKabupaten" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetKabupaten">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwKabupaten">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataKabupaten.list" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Kecamatan</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-6" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpKecamatan" class="form-control" 
                                placeholder="Ketik Kode Provinsi" />
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchKecamatan" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetKecamatan">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwKecamatan">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataKecamatan.list" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Diagnosa PRB</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwDiagnosaPRB">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataDiagnosaPRB.list" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Obat PRB</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-6" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpObatPRB" class="form-control" 
                                placeholder="Ketik Obat PRB" />
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchObatPRB" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetObatPRB">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwObatPRB">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataObatPRB.list" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>
            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Procedure</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-6" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpProcedure" class="form-control" 
                                placeholder="Ketik Procedure" />
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="searchProcedure" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetProcedure">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwProcedure">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataProcedure.procedure" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Kelas Rawat</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwKelasRawat">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataKelasRawat.list" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Ruang Rawat</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwRuangRawat">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (dataRuangRawat.list||[])" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                            <tbody v-if="!(dataRuangRawat.list||[]).length">
                                <tr>
                                    <td colspan="99">Tidak ada</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Spealistik</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwSpealistik">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (dataSpealistik.list||[])" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                            <tbody v-if="!(dataSpealistik.list||[]).length">
                                <tr>
                                    <td colspan="99">Tidak ada</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Cara Keluar</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwCaraKeluar">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (dataCaraKeluar.list||[])" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                            <tbody v-if="!(dataCaraKeluar.list||[]).length">
                                <tr>
                                    <td colspan="99">Tidak ada</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Pasca Pulang</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwPascaPulang">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>kode</th>
                                <th>nama</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (dataPascaPulang.list||[])" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.kode||"-"}}</td>
                                <td>{{v.nama||"-"}}</td>
                            </tr>
                            </tbody>
                            <tbody v-if="!(dataPascaPulang.list||[]).length">
                                <tr>
                                    <td colspan="99">Tidak ada</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>
        </div>
    </div>    
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'


export default {
  extends: GlobalVue,
  data() {
    return {
        inpDiagnosa:"",
        shwDiagnosa: false,  
        dataDiagnosa: {},

        inpPoli:"",
        shwPoli: false,  
        dataPoli: {},
        
        inpFaskes:"",
        jnsFaskes:null,
        shwFaskes: false,  
        dataFaskes: {},

        
        inpDPJP:"",
        jnsDPJP:null,
        shwDPJP: false,  
        dataDPJP: {},
        
        shwProvinsi: false,  
        dataProvinsi: {},
        
        inpKabupaten:"",
        shwKabupaten: false,  
        dataKabupaten: {},
    
        inpKecamatan:"",
        shwKecamatan: false,  
        dataKecamatan: {},
        
        shwDiagnosaPRB: false,  
        dataDiagnosaPRB: {},

        inpObatPRB:"",
        shwObatPRB: false,  
        dataObatPRB: {},
   
        inpProcedure:"",
        shwProcedure: false,  
        dataProcedure: {},

        
        shwKelasRawat: false,  
        dataKelasRawat: {},
        
        shwRuangRawat: false,  
        dataRuangRawat: {},

        shwSpealistik: false,  
        dataSpealistik: {},
        
        shwCaraKeluar: false,  
        dataCaraKeluar: {},
    
        shwPascaPulang: false,  
        dataPascaPulang: {},
    
    }
  },
  methods: {
    searchDiagnosa(){
        if(this.inpDiagnosa){
            this.loadingOverlay = true
            let data = {
                type : "search-diagnosa",
                search : this.inpDiagnosa
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwDiagnosa = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Diagnosa Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataDiagnosa = resp.response
                this.shwDiagnosa = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Diagnosa Tidak Ditemukan'
                })
            })
        }
    },
    resetDiagnosa(){
        this.inpDiagnosa = null
        this.shwDiagnosa = false
        this.dataDiagnosa = {}
    },


    searchPoli(){
        if(this.inpPoli){
            this.loadingOverlay = true
            let data = {
                type : "search-poli",
                search : this.inpPoli
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwPoli = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Poli Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataPoli = resp.response
                this.shwPoli = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Poli Tidak Ditemukan'
                })
            })
        }
    },
    resetPoli(){
        this.inpPoli = null
        this.shwPoli = false
        this.dataPoli = {}
    },

    searchFaskes(){
        if(this.inpFaskes && this.jnsFaskes){
            this.loadingOverlay = true
            let data = {
                type : "search-faskes",
                search : this.inpFaskes,
                jenis : this.jnsFaskes
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwFaskes = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Faskes Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataFaskes = resp.response
                this.shwFaskes = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Faskes Tidak Ditemukan'
                })
            })
        }
    },
    resetFaskes(){
        this.inpFaskes = null
        this.shwFaskes = false
        this.dataFaskes = {}
    },

    searchDPJP(){
        if(this.inpDPJP && this.jnsDPJP){
            this.loadingOverlay = true
            let data = {
                type : "search-dpjp",
                search : this.inpDPJP,
                jenis : this.jnsDPJP
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.shwDPJP = false
                    this.loadingOverlay = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data DPJP Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataDPJP = resp.response
                this.shwDPJP = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data DPJP Tidak Ditemukan'
                })
            })
        }
    },
    resetDPJP(){
        this.inpDPJP = null
        this.shwDPJP = false
        this.dataDPJP = {}
    },

    searchProvinsi(){
        this.loadingOverlay = true
        let data = {
            type : "search-provinsi",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail"){
                this.loadingOverlay = false
                this.shwProvinsi = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Provinsi Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataProvinsi = resp.response
            this.shwProvinsi = true
        }).catch(err=>{
                this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Provinsi Tidak Ditemukan'
            })
        })
    },

    searchKabupaten(){
        if(this.inpKabupaten){
            this.loadingOverlay = true
            let data = {
                type : "search-kabupaten",
                search : this.inpKabupaten
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwKabupaten = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Kabupaten Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataKabupaten = resp.response
                this.shwKabupaten = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Kabupaten Tidak Ditemukan'
                })
            })
        }
    },
    resetKabupaten(){
        this.inpKabupaten = null
        this.shwKabupaten = false
        this.dataKabupaten = {}
    },

    searchKecamatan(){
        if(this.inpKecamatan){
            this.loadingOverlay = true
            let data = {
                type : "search-kecamatan",
                search : this.inpKecamatan
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwKecamatan = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Kecamatan Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataKecamatan = resp.response
                this.shwKecamatan = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Kecamatan Tidak Ditemukan'
                })
            })
        }
    },
    resetKecamatan(){
        this.inpKecamatan = null
        this.shwKecamatan = false
        this.dataKecamatan = {}
    },
    
    searchDiagnosaPRB(){
        this.loadingOverlay = true
        let data = {
            type : "search-diagnosa-prb",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail"){
                this.loadingOverlay = false
                this.shwDiagnosaPRB = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Diagnosa PRB Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataDiagnosaPRB = resp.response
            this.shwDiagnosaPRB = true
        }).catch(err=>{
                this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Diagnosa PRB Tidak Ditemukan'
            })
        })
    },

    
    searchObatPRB(){
        if(this.inpObatPRB){
            this.loadingOverlay = true
            let data = {
                type : "search-obat-prb",
                search : this.inpObatPRB
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwObatPRB = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Obat PRB Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataObatPRB = resp.response
                this.shwObatPRB = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Obat PRB Tidak Ditemukan'
                })
            })
        }
    },
    resetObatPRB(){
        this.inpObatPRB = null
        this.shwObatPRB = false
        this.dataObatPRB = {}
    },


    searchProcedure(){
        if(this.inpProcedure){
            this.loadingOverlay = true
            let data = {
                type : "search-procedure",
                search : this.inpProcedure
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwProcedure = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Procedure Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataProcedure = resp.response
                this.shwProcedure = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Procedure Tidak Ditemukan'
                })
            })
        }
    },
    resetProcedure(){
        this.inpProcedure = null
        this.shwProcedure = false
        this.dataProcedure = {}
    },

    

    searchKelasRawat(){
        this.loadingOverlay = true
        let data = {
            type : "search-kelas-rawat",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail"){
                this.loadingOverlay = false
                this.shwKelasRawat = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Kelas Rawat Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataKelasRawat = resp.response
            this.shwKelasRawat = true
        }).catch(err=>{
                this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Kelas Rawat Tidak Ditemukan'
            })
        })
    },

    searchRuangRawat(){
        this.loadingOverlay = true
        let data = {
            type : "search-ruang-rawat",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail"){
                this.loadingOverlay = false
                this.shwRuangRawat = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Ruang Rawat Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataRuangRawat = resp.response
            this.shwRuangRawat = true
        }).catch(err=>{
                this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Ruang Rawat Tidak Ditemukan'
            })
        })
    },

    searchSpealistik(){
        this.loadingOverlay = true
        let data = {
            type : "search-spealistik",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail"){
                this.loadingOverlay = false
                this.shwSpealistik = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Spealistik Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataSpealistik = resp.response
            this.shwSpealistik = true
        }).catch(err=>{
                this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Spealistik Tidak Ditemukan'
            })
        })
    },

    searchCaraKeluar(){
        this.loadingOverlay = true
        let data = {
            type : "search-cara-keluar",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail"){
                this.loadingOverlay = false
                this.shwCaraKeluar = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Cara Keluar Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataCaraKeluar = resp.response
            this.shwCaraKeluar = true
        }).catch(err=>{
                this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Cara Keluar Tidak Ditemukan'
            })
        })
    },

    searchPascaPulang(){
        this.loadingOverlay = true
        let data = {
            type : "search-pasca-pulang",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail"){
                this.loadingOverlay = false
                this.shwPascaPulang = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Pasca Pulang Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataPascaPulang = resp.response
            this.shwPascaPulang = true
        }).catch(err=>{
                this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Pasca Pulang Tidak Ditemukan'
            })
        })
    },

    

  },
  mounted() {
    this.searchProvinsi()
    this.searchDiagnosaPRB()
    this.searchKelasRawat()
    this.searchRuangRawat()
    this.searchSpealistik()
    this.searchCaraKeluar()
    this.searchPascaPulang()


  },
  

}
</script>